import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="disable-button"
export default class extends Controller {
  static targets = ["button"];

  disable() {
    setTimeout(() => {
      this.buttonTarget.disabled = true;

      // Automatically re-enable the button after 10 seconds
      setTimeout(() => {
        this.buttonTarget.disabled = false;
      }, 10000);
    }, 100);
  }
}
