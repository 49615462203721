import { Controller } from "@hotwired/stimulus";
import { fetchWithTurboStream } from "../utils/fetchUtils";

// Connects to data-controller="address-form"
export default class extends Controller {
  static targets = [
    "input",
    "error",
    "phaseGroup",
    "buildingGroup",
    "floorUnit",
    "searchField",
    "manualForm",
    "manualAddress",
  ];
  static values = { isSelfListing: Boolean };

  handleInput() {
    this.inputTarget.classList.toggle("search-address-invalid", true);
    this.phaseGroupTarget.innerHTML = "";
    this.buildingGroupTarget.innerHTML = "";

    this.toggleFormElements(this.floorUnitTarget, false);
  }

  onEstateChange(event) {
    const data = event.currentTarget.dataset;
    const singleBuilding = data.singleBuilding === "true";
    const village = data.village === "true";

    this.toggleFormElements(this.floorUnitTarget, singleBuilding);

    this.inputTarget.classList.toggle("search-address-invalid", false);
    if (this.hasErrorTarget) {
      this.errorTarget.classList.add("d-none");
    }

    this.toggleNoticeVisibility(village && !this.isSelfListingValue);
  }

  onPhaseChange(event) {
    fetchWithTurboStream(
      `/${window.currentLocale}/phases/${event.target.value}/info`,
      { method: "GET" },
    );
    this.toggleFormElements(this.floorUnitTarget, false);
  }

  onBuildingChange() {
    this.toggleFormElements(this.floorUnitTarget, true);
  }

  showManualForm() {
    if (this.inputTarget.value && this.hasManualAddressTarget) {
      this.manualAddressTarget.value = this.inputTarget.value; // Copy value
    }

    this.toggleFormElements(this.searchFieldTarget, false);
    this.phaseGroupTarget.innerHTML = "";
    this.buildingGroupTarget.innerHTML = "";
    this.toggleFormElements(this.floorUnitTarget, false);
    this.errorTarget.classList.add("d-none");

    this.toggleFormElements(this.manualFormTarget, true);

    this.toggleNoticeVisibility(!this.isSelfListingValue);
  }

  showSearchForm(event) {
    event.preventDefault();

    if (this.hasManualAddressTarget && this.manualAddressTarget.value) {
      this.inputTarget.value = this.manualAddressTarget.value; // Copy value
    }

    this.toggleFormElements(this.searchFieldTarget, true);

    this.inputTarget.focus();

    this.toggleFormElements(this.manualFormTarget, false);

    this.toggleNoticeVisibility(false);
  }

  toggleFormElements(target, isRequired) {
    target.classList.toggle("d-none", !isRequired);
    const elements = target.querySelectorAll("input, select, textarea");

    elements.forEach((element) => {
      element.required = isRequired;

      if (!isRequired) {
        element.value = "";
      }
    });
  }

  toggleNoticeVisibility(isVisible) {
    const element = document.getElementById("village-house-notice");

    if (element) {
      element.classList.toggle("d-none", !isVisible);
    }
  }
}
