import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="swiper"
export default class extends Controller {
  static Swiper;
  static Navigation;
  static Pagination;

  static values = { desktopSlides: Number };

  async connect() {
    if (!this.constructor.Swiper) {
      const { default: Swiper } = await import("swiper");
      const { Navigation, Pagination } = await import("swiper/modules");

      this.constructor.Swiper = Swiper;
      this.constructor.Navigation = Navigation;
      this.constructor.Pagination = Pagination;

      Swiper.use([Navigation, Pagination]);
    }

    this.fractionPagination = this.element.querySelector(
      ".fraction-pagination",
    );

    this.swiper = new this.constructor.Swiper(this.element, {
      lazy: true,
      slidesPerView: 1,
      breakpoints: {
        1400: {
          slidesPerView: this.desktopSlidesValue || 1,
        },
      },
      pagination: {
        el: ".swiper-pagination",
        dynamicBullets: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      on: {
        slideChange: () => {
          if (this.fractionPagination) {
            this.fractionPagination.textContent = `${this.swiper.realIndex + 1} / ${this.swiper.slides.length}`;
          }
        },
      },
    });

    if (this.fractionPagination) {
      this.fractionPagination.textContent = `1 / ${this.swiper.slides.length}`;
    }
  }

  disconnect() {
    if (this.swiper && !this.swiper.destroyed) {
      this.swiper.destroy();
      this.swiper = null;
    }
  }
}
