import { Controller } from "@hotwired/stimulus";
import introJs from "intro.js";
import { isMobile } from "../utils/utils";

// Connects to data-controller="tour"
export default class extends Controller {
  static values = { steps: Array };

  connect() {
    if (!isMobile()) {
      const allElementsAvailable = this.stepsValue.every((step) =>
        document.querySelector(step.element),
      );
      if (allElementsAvailable) {
        this.initializeTour();
      } else {
        console.error(
          "One or more tour steps cannot find their target elements.",
        );
      }
    }
  }

  initializeTour() {
    this.tour = introJs();
    this.tour.setOptions({
      steps: this.stepsValue.map((step) => ({
        element: document.querySelector(step.element),
        intro: step.intro,
      })),
      exitOnOverlayClick: false,
      showStepNumbers: false,
      showBullets: true,
    });

    this.tour.oncomplete(async () => {
      try {
        const response = await fetch("/users/update_tutorial_status", {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": document
              .querySelector('meta[name="csrf-token"]')
              .getAttribute("content"),
          },
          body: JSON.stringify({ tutorial_completed: true }),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
      } catch (error) {
        console.error("Failed to update tutorial status:", error);
      }
    });

    setTimeout(() => this.tour.start(), 1000);
  }
}
