import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="notifications"
export default class extends Controller {
  static values = {
    type: String,
    message: String,
  };

  connect() {
    if (this.hasTypeValue && this.hasMessageValue) {
      this.showNotification();
    }
  }

  async showNotification() {
    const SwalModule = await import("sweetalert2");
    const Swal = SwalModule.default;

    Swal.fire({
      icon: this.typeValue,
      title: this.messageValue,
      showConfirmButton: false,
      timer: 1500,
    });
  }
}
