import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="toggle"
export default class extends Controller {
  static targets = ["content", "toggleButton", "showMore", "showLess"];

  connect() {
    this.checkContentHeight();
  }

  toggle() {
    this.contentTarget.classList.toggle("open");
    this.showMoreTarget.classList.toggle("d-none");
    this.showLessTarget.classList.toggle("d-none");
  }

  checkContentHeight() {
    const contentHeight = this.contentTarget.scrollHeight; // Total height of content
    const visibleHeight = this.contentTarget.offsetHeight; // Currently visible height

    if (contentHeight > visibleHeight) {
      this.toggleButtonTarget.classList.remove("d-none");
    }
  }
}
