import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="districts"
export default class extends Controller {
  static targets = ["superDistrict", "district"];

  toggle() {
    const isChecked = this.superDistrictTarget.checked;

    this.districtTargets.forEach((district) => {
      district.checked = isChecked;
    });
  }
}
