import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="category"
export default class extends Controller {
  static targets = ["link"];

  connect() {}

  toggleClass(event) {
    this.linkTargets.forEach((element) => {
      element.classList.remove("btn-info");
      element.classList.add("btn-outline-info", "border-0", "shadow-sm");
    });

    event.currentTarget.classList.remove(
      "btn-outline-info",
      "border-0",
      "shadow-sm",
    );
    event.currentTarget.classList.add("btn-info");
  }
}
