import bootstrap from "../bootstrap";
import { fetchWithTurboStream } from "./fetchUtils";

// Utility to close all open modals
export function closeModal() {
  const modalElement = document.getElementById("modal");
  const modalInstance = bootstrap.Modal.getInstance(modalElement);
  if (modalInstance) {
    modalInstance.hide();
  }
}

// Utility to open a specific modal by ID
export function openModal() {
  const modalElement = document.getElementById("modal");

  if (modalElement) {
    if (!modalElement.classList.contains("show")) {
      const modalInstance = new bootstrap.Modal(modalElement, {
        focus: true, // Automatically focus the modal when opened
      });
      modalInstance.show();
    }
  }
}

export async function fetchAndDisplayModal(modalType, params = {}) {
  const url = new URL(
    `/${window.currentLocale}/show_modal/${modalType}`,
    window.location.origin,
  );
  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key]),
  );

  await fetchWithTurboStream(url.toString(), { method: "GET" });
}
