import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="chat-trigger"
export default class extends Controller {
  static values = {
    conversationId: { type: Number, default: undefined },
  };

  startChat(event) {
    event.preventDefault();
    const chatEvent = new CustomEvent("chat:start", {
      detail: {
        init: {
          conversation_id: this.conversationIdValue,
        },
      },
      bubbles: true,
    });
    this.element.dispatchEvent(chatEvent);
  }
}
