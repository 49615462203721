import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="scroll-indicator"
export default class extends Controller {
  static values = { targetId: String };

  scrollDown() {
    const element = document.getElementById(this.targetIdValue);
    element.scrollIntoView({ behavior: "smooth" });
  }
}
