import { Controller } from "@hotwired/stimulus";
import { fetchWithTurboStream } from "../utils/fetchUtils";
import { isMobile } from "../utils/utils";

// Connects to data-controller="content-loader"
export default class extends Controller {
  static values = { url: String, hasMore: Boolean, nextPage: Number };

  connect() {
    if (isMobile()) {
      this.scrollHandler = this.loadMore.bind(this); // Bind the method to keep the context
      window.addEventListener("scroll", this.scrollHandler);
    }
  }

  disconnect() {
    window.removeEventListener("scroll", this.scrollHandler); // Clean up
  }

  async loadMore() {
    // Load more content only if the user has scrolled near the bottom of the page
    if (this.hasMoreValue && this.nearBottomOfPage()) {
      this.element.classList.remove("d-none");
      this.hasMoreValue = false; // Prevent further calls until the fetch completes
      const url = new URL(this.urlValue, window.location.origin);
      url.searchParams.set("page", this.nextPageValue);
      await fetchWithTurboStream(url);
    }
  }

  nearBottomOfPage() {
    return (
      window.scrollY + window.innerHeight >=
      document.documentElement.scrollHeight - 1000
    );
  }
}
