import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="editable-field"
export default class extends Controller {
  static targets = ["display", "form", "addLink", "cancelLink"];

  toggleEdit() {
    // Toggle visibility of the display and form elements
    this.displayTarget.classList.toggle("d-none");
    this.formTarget.classList.toggle("d-none");
    this.addLinkTarget.classList.toggle("d-none");
    this.cancelLinkTarget.classList.toggle("d-none");
  }
}
