import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="slider"
export default class extends Controller {
  static values = {
    start: Array,
    min: Number,
    max: Number,
    step: { type: Number, default: 1 },
    isCurrency: Boolean,
    range: Object,
    titleId: String,
    suffix: String,
    showTooltips: { type: Boolean, default: false },
  };

  static targets = ["slider", "minInput", "maxInput", "histogramBar"];

  async connect() {
    const noUiSlider = await import("nouislider/dist/nouislider.mjs");
    const effectiveRange = this.hasRangeValue
      ? this.rangeValue
      : { min: this.minValue, max: this.maxValue };

    noUiSlider.create(this.sliderTarget, {
      start: this.startValue,
      connect: true,
      range: effectiveRange,
      step: this.stepValue,
      format: this.isCurrencyValue
        ? this.currencyFormat()
        : this.defaultFormat(),
    });

    this.sliderTarget.noUiSlider.on(
      "update",
      (rawValues, handle, unencoded) => {
        this.updateValues(rawValues, unencoded);
      },
    );
  }

  updateValues(rawValues, unencoded) {
    this.minInputTarget.value = unencoded[0];
    this.maxInputTarget.value = unencoded[1];

    const event = new Event("input", { bubbles: true, cancelable: true });
    this.minInputTarget.dispatchEvent(event);
    this.maxInputTarget.dispatchEvent(event);

    if (this.showTooltipsValue) {
      const minTooltip = this.element.querySelector(".min-tooltip");
      const maxTooltip = this.element.querySelector(".max-tooltip");

      if (minTooltip) {
        minTooltip.textContent = rawValues[0];
      }

      if (maxTooltip) {
        maxTooltip.textContent = rawValues[1];
      }
    }

    const titleElement = document.getElementById(this.titleIdValue);

    if (titleElement) {
      let suffix = this.hasSuffixValue ? ` ${this.suffixValue}` : "";
      if (unencoded[0] !== 1 || unencoded[1] !== 1) {
        if (suffix.match(/[A-Za-z]$/) && !suffix.endsWith("s")) {
          suffix += "s";
        }
      }

      titleElement.textContent =
        (rawValues[0] === rawValues[1]
          ? rawValues[0]
          : `${rawValues[0]} - ${rawValues[1]}`) + suffix;
    }

    this.updateHistogram(unencoded[0], unencoded[1]);
  }

  disconnect() {
    if (this.sliderTarget.noUiSlider) {
      this.sliderTarget.noUiSlider.destroy();
    }
  }

  defaultFormat() {
    return {
      to: function (value) {
        return Math.round(value).toString();
      },
      from: function (value) {
        return parseFloat(value);
      },
    };
  }

  currencyFormat() {
    return {
      to: function (value) {
        return `$${(value / 100000).toFixed(0)}k`;
      },
      from: function (value) {
        return parseFloat(value.replace(/\$\s?|k/g, "")) * 100000;
      },
    };
  }

  updateHistogram(minPrice, maxPrice) {
    this.histogramBarTargets.forEach((bar) => {
      const priceMin = parseFloat(bar.dataset.priceMin);
      const priceMax = parseFloat(bar.dataset.priceMax);

      if (priceMin >= minPrice && priceMax <= maxPrice) {
        bar.classList.replace("bg-primary-subtle", "bg-primary");
      } else {
        bar.classList.replace("bg-primary", "bg-primary-subtle");
      }
    });
  }
}
