export function updateButtonState(button, isDisabled) {
  if (button) {
    button.disabled = isDisabled;
  }
}

export function toggleButtonState(button, isLoading) {
  if (button instanceof HTMLElement) {
    button.disabled = isLoading;
    const spinner = button.querySelector(".position-absolute");

    if (spinner) {
      spinner.classList.toggle("d-none", !isLoading);
      button
        .querySelector('[role="status"]')
        .classList.toggle("invisible", isLoading);
    }
  } else {
    console.warn(
      "toggleButtonState was called with an invalid button element",
      button,
    );
  }
}

export function toggleSearchButton(button, isExpanded) {
  const span = button.querySelector("span");

  span.classList.toggle("d-none", !isExpanded);
  button.classList.toggle("no-transition", isExpanded);
  button.classList.toggle("expanded", isExpanded);
}
