import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="quick-reply-select"
export default class extends Controller {
  static targets = ["button"];

  toggleSelect(event) {
    this.buttonTargets.forEach((button) => {
      button.classList.remove("disabled");
      button.disabled = false;
    });

    event.currentTarget.classList.add("disabled");
    event.currentTarget.disabled = true;
  }
}
