import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="pdf-reader"
export default class extends Controller {
  static targets = ["canvas", "pageIndicator", "buttonPrev", "buttonNext"];
  static values = { url: String };

  currentPage = 1;
  // this.currentPage
  pdf = null;

  getDocument = null;

  async connect() {
    await this.lazyLoadPdfJs();
    await this.loadPdf();
    this.updatePageIndicator();
  }

  async loadPdf() {
    const loadingTask = this.getDocument(this.urlValue);
    this.pdf = await loadingTask.promise;
    this.renderPage();
  }

  async lazyLoadPdfJs() {
    // Dynamically import the pdfjs-dist library and worker
    const pdfjsLib = await import("pdfjs-dist/build/pdf");

    // Configure the workerSrc for pdfjs
    pdfjsLib.default.GlobalWorkerOptions.workerSrc = "/pdf.worker.js";

    // Make getDocument available for later use
    this.getDocument = pdfjsLib.default.getDocument;
  }

  async renderPage() {
    const page = await this.pdf.getPage(this.currentPage);
    const viewport = page.getViewport({ scale: 1.75 });
    const canvas = this.canvasTarget;
    canvas.width = viewport.width;
    canvas.height = viewport.height;

    const context = canvas.getContext("2d");
    const renderContext = {
      canvasContext: context,
      viewport: viewport,
    };
    await page.render(renderContext);
    this.updatePageIndicator();
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage -= 1;
      this.renderPage();
    }

    if (this.currentPage === 1) {
      this.buttonPrevTarget.disabled = true;
    }

    this.buttonNextTarget.disabled = false;
  }

  nextPage() {
    if (this.currentPage < this.pdf.numPages) {
      this.currentPage += 1;
      this.renderPage();
    }

    if (this.currentPage === this.pdf.numPages) {
      this.buttonNextTarget.disabled = true;
    }

    this.buttonPrevTarget.disabled = false;
  }

  updatePageIndicator() {
    if (this.hasPageIndicatorTarget) {
      this.pageIndicatorTarget.textContent = `${this.currentPage} / ${this.pdf.numPages}`;
    }
  }
}
