import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="faq-collapse"
export default class extends Controller {
  static targets = ["question", "content", "plusIcon", "minusIcon"];
  static openItem = null;

  toggle() {
    if (this.constructor.openItem && this.constructor.openItem !== this) {
      this.constructor.openItem.close();
    }

    this.questionTarget.classList.toggle("text-info");
    this.plusIconTarget.classList.toggle("d-none");
    this.minusIconTarget.classList.toggle("d-none");

    if (!this.contentTarget.classList.contains("show")) {
      this.open();
    } else {
      this.close();
    }
  }

  open() {
    this.contentTarget.classList.add("show");
    this.contentTarget.style.height = this.contentTarget.scrollHeight + "px";
    this.constructor.openItem = this;
  }

  close() {
    this.questionTarget.classList.remove("text-info");
    this.contentTarget.style.height = "0";
    this.contentTarget.classList.remove("show");
    this.plusIconTarget.classList.remove("d-none");
    this.minusIconTarget.classList.add("d-none");
    this.constructor.openItem = null;
  }
}
