const translations = {
  en: {
    are_you_sure: "Are you sure?",
    yes: "Yes",
    no: "No",
  },
  "zh-HK": {
    are_you_sure: "您確定嗎？",
    yes: "是",
    no: "否",
  },
  "zh-CN": {
    are_you_sure: "您确定吗？",
    yes: "是",
    no: "否",
  },
};

export default translations;
