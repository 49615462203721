import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="otp-input"
export default class extends Controller {
  static targets = ["input"];

  handleKeyUp(event) {
    const input = event.target;
    const index = this.inputTargets.indexOf(input);

    if (event.key === "Backspace" || event.key === "Delete") {
      if (index > 0) {
        this.inputTargets[index - 1].focus();
      }
    } else if (input.value.length === 1) {
      if (index < this.inputTargets.length - 1) {
        this.inputTargets[index + 1].focus();
      }
    }
  }

  handleInput(event) {
    const input = event.target;
    const inputValue = input.value;

    if (inputValue.length === 6) {
      this.distributeDigits(inputValue);
      this.inputTargets[this.inputTargets.length - 1].focus();
    } else if (inputValue.length > 1) {
      input.value = inputValue.charAt(0);
    }

    const otp = this.inputTargets.map((input) => input.value).join("");
    if (otp.length === 6 && /^\d{6}$/.test(otp)) {
      event.target
        .closest("form")
        .dispatchEvent(new Event("submit", { bubbles: true }));
    }
  }

  distributeDigits(fullOtp) {
    const digits = fullOtp.split("").slice(0, this.inputTargets.length);

    // Assign each digit to the corresponding input field
    this.inputTargets.forEach((input, index) => {
      input.value = digits[index] || "";
    });

    const lastInput = this.inputTargets[this.inputTargets.length - 1];
    // Create and dispatch an 'input' event on the last input field
    const event = new Event("input", { bubbles: true, cancelable: true });
    lastInput.dispatchEvent(event);
  }
}
