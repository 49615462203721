import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="counter"
export default class extends Controller {
  static targets = ["number", "input", "decrementButton"];
  static values = {
    anyText: String, // Define a static value for the 'Any' text
  };

  connect() {
    this.numberTarget.textContent = this.inputTarget.value || this.anyTextValue;
    this.updateButtonState();
  }

  increment() {
    const currentValue =
      parseInt(this.numberTarget.textContent.replace(/\+/g, "")) || 0;
    this.numberTarget.textContent = currentValue + 1;
    this.updateNumber();
    this.updateButtonState();
  }

  decrement() {
    const currentValue =
      parseInt(this.numberTarget.textContent.replace(/\+/g, "")) || 0;
    if (currentValue > 0) {
      this.numberTarget.textContent = currentValue - 1;
      this.updateNumber();
      this.updateButtonState();
    }
  }

  updateNumber() {
    const currentValue = parseInt(this.numberTarget.textContent);
    this.inputTarget.value = currentValue || "";
    const event = new Event("input", { bubbles: true });
    this.inputTarget.dispatchEvent(event);
    this.numberTarget.textContent =
      currentValue === 0 ? this.anyTextValue : currentValue + "+";
  }

  updateButtonState() {
    const currentValue = parseInt(this.numberTarget.textContent) || 0;
    const isDisabled = currentValue === 0;
    this.decrementButtonTarget.disabled = isDisabled;
  }
}
