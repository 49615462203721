import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="sign-in"
export default class extends Controller {
  static targets = [
    "emailSection",
    "phoneSection",
    "emailButton",
    "phoneButton",
  ];

  toggleForm(event) {
    const isEmailButton = event.currentTarget === this.emailButtonTarget;

    this.emailSectionTarget.classList.toggle("d-none", !isEmailButton);
    this.phoneSectionTarget.classList.toggle("d-none", isEmailButton);
    this.emailButtonTarget.classList.toggle("d-none", isEmailButton);
    this.phoneButtonTarget.classList.toggle("d-none", !isEmailButton);
  }
}
