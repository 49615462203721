import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="structured-data"
export default class extends Controller {
  static values = {
    data: Array,
  };

  connect() {
    this.updateStructuredData();
  }

  updateStructuredData() {
    const head = document.querySelector("head");
    if (!head) return;

    // Remove existing structured data scripts
    head
      .querySelectorAll("script[type='application/ld+json']")
      .forEach((script) => script.remove());

    // Iterate over the structured data values and append them
    this.dataValue.forEach((dataItem) => {
      if (dataItem) {
        const script = document.createElement("script");
        script.type = "application/ld+json";
        script.textContent = JSON.stringify(dataItem);
        head.appendChild(script);
      }
    });
  }
}
