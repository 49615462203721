import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="date-selector"
export default class extends Controller {
  static targets = ["day"];

  connect() {
    // This method is called when the controller is connected to the DOM
  }

  selectDay(event) {
    if (
      !event.currentTarget
        .querySelector("span")
        .classList.contains("link-disabled")
    ) {
      document.querySelectorAll(".day").forEach((d) => {
        d.classList.remove("active");
      });

      const clickedDay = event.currentTarget;
      clickedDay.querySelector("span").classList.add("active");
    }
  }
}
