import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="search"
export default class extends Controller {
  static values = { hashShort: String };

  connect() {
    const newURL = this.hashShortValue
      ? `${window.location.pathname}?h=${this.hashShortValue}`
      : window.location.pathname;

    history.pushState({}, "", newURL);
  }
}
