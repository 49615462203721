import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="pricing"
export default class extends Controller {
  static targets = [
    "rent",
    "commission",
    "defaultCommission",
    "dynamicCommission",
  ];

  updateCommission() {
    const rentValue = this.rentTarget.value;
    if (rentValue.trim() === "" || parseFloat(rentValue) < 1000) {
      this.defaultCommissionTarget.classList.remove("d-none");
      this.dynamicCommissionTarget.classList.add("d-none");
    } else {
      this.defaultCommissionTarget.classList.add("d-none");
      this.dynamicCommissionTarget.classList.remove("d-none");
      const commissionRate = 0.25;
      const calculatedCommission = parseFloat(rentValue) * commissionRate;
      const formattedCommission = calculatedCommission.toLocaleString("en-HK", {
        style: "currency",
        currency: "HKD",
        maximumFractionDigits: 0,
      });
      this.dynamicCommissionTarget.textContent = formattedCommission;
    }
  }
}
