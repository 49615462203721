import { Controller } from "@hotwired/stimulus";
import { fetchWithTurboStream } from "../utils/fetchUtils";

export default class extends Controller {
  static targets = ["submitButton", "buttonText", "spinner", "form"];

  connect() {
    // Initialize the count on connect
    this.fetchCount();

    this.debounceTimeout = null;
  }

  disconnect() {
    clearTimeout(this.debounceTimeout);
  }

  submitForm() {
    this.formTarget.requestSubmit();
  }

  updateCount() {
    // Show loading indicator on the submit button
    this.buttonTextTarget.classList.add("invisible");
    this.spinnerTarget.classList.remove("d-none");

    clearTimeout(this.debounceTimeout);

    this.debounceTimeout = setTimeout(() => {
      this.fetchCount();
    }, 300);
  }

  async fetchCount() {
    const form = document.getElementById("filtersForm");
    const formData = new FormData(form);
    const params = new URLSearchParams(formData).toString();
    const url = `/${window.currentLocale}/listings/count_results?${params}`;

    await fetchWithTurboStream(url, { method: "GET" });
    this.spinnerTarget.classList.add("d-none");
    this.buttonTextTarget.classList.remove("invisible");
  }

  clearFilters(event) {
    event.preventDefault();
    const form = document.getElementById("filtersForm");
    form.reset();
    this.submitButtonTarget.innerText = "Show 0 Results";
  }
}
