import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="owner-fields"
export default class extends Controller {
  static targets = ["companyDetails"];

  toggle(event) {
    const isCompany = event.target.value === "true";
    this.companyDetailsTarget.classList.toggle("d-none", !isCompany);

    const companyInputs = this.companyDetailsTarget.querySelectorAll(
      "input, textarea, select",
    );
    companyInputs.forEach((input) => {
      if (isCompany) {
        input.required = true;
      } else {
        input.required = false;
        // Clear the input value for non-company (individual) case
        input.value = "";
        // For select elements, reset to default (first option) if necessary
        if (input.tagName.toLowerCase() === "select") {
          input.selectedIndex = 0;
        }
      }
    });
  }
}
