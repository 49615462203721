export async function fetchWithTurboStream(url, options = {}) {
  try {
    // Set default headers and merge with any additional options
    const defaultHeaders = {
      "Content-Type": "application/json",
      Accept: "text/vnd.turbo-stream.html",
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
    };

    const finalOptions = {
      ...options,
      headers: {
        ...defaultHeaders,
        ...options.headers,
      },
    };

    const response = await fetch(url, finalOptions);

    if (response.ok) {
      const html = await response.text();
      Turbo.renderStreamMessage(html);
    } else {
      throw new Error("Network response was not ok.");
    }
  } catch (error) {
    console.error("Fetch with Turbo Stream error: ", error);
  }
}
