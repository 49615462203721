import { Controller } from "@hotwired/stimulus";
import { createGeneralChatChannel } from "../channels/chat_channel";
import { extractConversationDetails, getLocalStorageItem } from "../utils/chat";
import autosize from "autosize";

// Connects to data-controller="chat"
export default class extends Controller {
  static targets = ["bubble", "overview", "window", "list"];

  async connect() {
    const conversationId = getLocalStorageItem("conversationId");
    this.generalChatChannel = createGeneralChatChannel();
    this.loadChatSummaries();

    if (this.windowTarget.classList.contains("active")) {
      const conversationDetails = extractConversationDetails(this.windowTarget);
      await this.initializeChat(conversationDetails);
    } else if (conversationId) {
      await this.startNewConversation({ conversation_id: conversationId });
    }

    this.boundHandleChatStart = this.handleChatStart.bind(this);
    document.addEventListener("chat:start", this.boundHandleChatStart);

    this.isLoadingValue = false;
  }

  disconnect() {
    this.generalChatChannel.unsubscribe();

    this.unsubscribeFromChatChannel();
    document.removeEventListener("chat:start", this.boundHandleChatStart);
  }

  handleChatStart(event) {
    this.startNewConversation(event.detail.init);
  }

  onScroll() {
    let bottom =
      this.listTarget.scrollHeight - this.listTarget.scrollTop ===
      this.listTarget.clientHeight;
    if (bottom) {
      this.loadChatSummaries(true);
    }
  }

  handleNewMessageSubmit(e) {
    e.preventDefault();
    const messageContentElement = document.getElementById("message-content");
    const messageContent = messageContentElement.value;
    this.chatChannel.speak(messageContent);
    messageContentElement.value = "";
    autosize.update(messageContentElement);
  }

  checkForEnter(event) {
    if (event.keyCode === 13 && !event.shiftKey) {
      // 13 is the Enter key
      this.handleNewMessageSubmit(event);
    }
  }

  handleReplyClick(event) {
    let replyContent = event.currentTarget.getAttribute("data-reply-value");
    this.chatChannel.speak(replyContent);
    document.getElementById("message-content").value = "";
  }

  async handleFileUpload(event) {
    const files = event.target.files;

    if (files.length) {
      Array.from(files).forEach((file) => {
        if (file.type.startsWith("image/")) {
          this.chatChannel.speak("", file); // Call 'speak' for each image
        }
      });

      event.target.value = "";
    }
  }
}
