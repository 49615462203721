export function extractConversationDetails(element) {
  const id = parseInt(element.getAttribute("data-conversation-id"), 10);
  const title = element.getAttribute("data-title");
  const status = element.getAttribute("data-status");

  return {
    id,
    title,
    status,
  };
}

export function getLocalStorageItem(key) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    // No item found for the given key
    return null;
  }
  const item = JSON.parse(itemStr);
  if (item.expiry) {
    const now = new Date();
    if (now.getTime() > item.expiry) {
      // Expiry has passed, clear the item and return null
      localStorage.removeItem(key);
      return null;
    }
  }
  return item.value;
}

export function setLocalStorageItem(key, value, expiryHours = null) {
  const item = { value: value };
  if (expiryHours) {
    const now = new Date();
    item.expiry = now.getTime() + expiryHours * 60 * 60 * 1000;
  }
  localStorage.setItem(key, JSON.stringify(item));
}
