import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="text-area"
export default class extends Controller {
  static targets = ["textArea", "toggleButton"];
  static values = { expandText: String, collapseText: String };

  toggleSize() {
    const minHeight = this.textAreaTarget.style.minHeight;
    if (minHeight === "206px" || minHeight === "") {
      this.textAreaTarget.style.minHeight = "800px"; // Expanded size
      this.toggleButtonTarget.textContent = this.collapseTextValue; // Change button text
    } else {
      this.textAreaTarget.style.minHeight = "206px"; // Original size
      this.toggleButtonTarget.textContent = this.expandTextValue; // Change button text back
    }
  }
}
