import { Controller } from "@hotwired/stimulus";
import { toggleButtonState } from "../utils/buttons";

// Connects to data-controller="loading-button"
export default class extends Controller {
  static values = { id: String };
  static targets = ["spinner", "text"];

  connect() {
    if (this.idValue) {
      toggleButtonState(document.getElementById(this.idValue), false);
    }
  }

  submit() {
    this.spinnerTarget.classList.remove("d-none");
    this.textTarget.classList.add("invisible");
  }
}
