import { Controller } from "@hotwired/stimulus"
import bootstrap from "../bootstrap";

// Connects to data-controller="tab-navigation"
export default class extends Controller {
  activate(event) {
    const tab = new bootstrap.Tab(event.currentTarget);
    tab.show(); // Show the tab
  }
}
