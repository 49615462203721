export async function loadCss(url) {
  return new Promise((resolve, reject) => {
    const link = document.createElement("link");
    link.href = url;
    link.rel = "stylesheet";
    link.onload = () => resolve();
    link.onerror = () => reject(new Error(`Style load error for ${url}`));
    document.head.appendChild(link);
  });
}
