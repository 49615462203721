import consumer from "./consumer";

function createChatChannel(conversationDetails) {
  return consumer.subscriptions.create(
    {
      channel: "ChatChannel",
      conversation_details: conversationDetails,
    },
    {
      connected() {},

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      speak: function (content, imageFile = null) {
        const data = { content: content };

        if (imageFile) {
          const reader = new FileReader();
          reader.onload = (e) => {
            data.image = e.target.result;
            this.perform("speak", data);
          };
          reader.readAsDataURL(imageFile);
        } else {
          this.perform("speak", data);
        }
      },
    },
  );
}

function createGeneralChatChannel() {
  return consumer.subscriptions.create(
    {
      channel: "ChatChannel",
      // Passing minimal or no parameters since this is a general listener
    },
    {
      received(data) {
        switch (data.action) {
          case "new_message":
            const chatOverview = document.getElementById("chat-overview");
            const chatWindow = document.getElementById("chat-window");

            // Check if 'chat-overview' and 'chat-window' have 'd-none'
            const isOverviewHidden = chatOverview.classList.contains("d-none");
            const isWindowHidden = chatWindow.classList.contains("d-none");

            // Determine visibility action based on the state of 'chat-window'
            if (isWindowHidden) {
              // If the chat window is hidden, show chat-overview
              if (isOverviewHidden) {
                chatOverview.classList.remove("d-none");
              }
            } else {
              // If the chat window is visible, do not disturb the user
              // Maybe highlight or badge the chat-overview to indicate new message
              // indicateChangeInOverview();
            }
            break;
        }
      },
    },
  );
}

export { createChatChannel, createGeneralChatChannel };
