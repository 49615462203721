import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="scroll"
export default class extends Controller {
  connect() {
    this.lastScrollTop = 0;

    window.addEventListener("scroll", this.handleScroll.bind(this));
  }

  disconnect() {
    window.removeEventListener("scroll", this.handleScroll.bind(this));
  }

  handleScroll() {
    const currentScroll = window.scrollY || document.documentElement.scrollTop;

    if (currentScroll > this.lastScrollTop) {
      // Scrolling down
      this.element.classList.add("hide-navbar");
      this.element.classList.remove("show-navbar");
    } else {
      // Scrolling up
      this.element.classList.remove("hide-navbar");
      this.element.classList.add("show-navbar");
    }

    this.lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
  }
}
