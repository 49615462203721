import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="chart"
export default class extends Controller {
  static values = {
    data: Array, // Assumes this is an array of objects {name, data}
    ytitle: String,
    xtitle: String,
  };

  async connect() {
    const { Chart, registerables } = await import("chart.js");
    Chart.register(...registerables);

    const ctx = this.element.querySelector("canvas").getContext("2d");

    const colors = [
      "rgb(255, 99, 132)",
      "rgb(22,160,133)",
      "rgb(241,196,15)",
      "rgb(231,76,60)",
      "rgb(52, 152, 219)",
      "rgb(155, 89, 182)",
      "rgb(46,204,113)",
      "rgb(52,73,94)",
      "rgb(230,126,34)",
      "rgb(127,140,141)",
    ];

    const hasSearches = this.dataValue.some(
      (dataset) => dataset.name === "Rental requirements",
    );

    const scalesOptions = {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: this.ytitleValue,
        },
      },
      yRight: {
        type: "linear",
        display: hasSearches, // Initially set to false, and will be toggled based on condition
        position: "right",
        beginAtZero: true,
        title: {
          display: hasSearches,
          text: "Rental Requirements", // This text can be dynamic or conditional as needed
        },
        grid: {
          drawOnChartArea: false, // Only draw grid lines for this axis
        },
      },
      x: {
        // Setup the x-axis, always displayed
        title: {
          display: this.hasXtitleValue, // Use the conditional directly here
          text: this.xtitleValue || "", // Provide a default text if xtitleValue is undefined
        },
      },
    };

    new Chart(ctx, {
      type: "line",
      data: {
        datasets: this.dataValue.map((dataset, index) => ({
          label: dataset.name,
          data: dataset.data,
          yAxisID: dataset.yAxisID,
          fill: false,
          borderColor: colors[index % colors.length],
          tension: 0.1,
        })),
      },
      options: {
        scales: scalesOptions,
      },
    });
  }
}
